import axios from "axios";
import store from "../store";
import { LOGOUT } from "../modules/User/constants";
import setAuthToken from "./setAuthToken";

const api = axios.create({
  baseURL: "/api/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (res) => res,
  async (err) => {
    const localRefreshToken = localStorage.getItem("refreshToken");

    if (
      localRefreshToken &&
      err.response.status === 401 &&
      err.response.data?.error === "Token has expired"
    ) {
      try {
        const res = await axios.post(`/api/v1/auth/refresh`, {
          refreshToken: localRefreshToken,
        });

        if (res.data.success) {
          const { token, refreshToken } = res.data;
          setAuthToken(token, refreshToken);
        }
      } catch (error) {
        const user = store.getState()?.auth?.user;
        if (error.response.data.message === "jwt expired") {
          setAuthToken();
          await axios.post(`/api/v1/auth/logout`, { userId: user._id });
          store.dispatch({ type: LOGOUT });
        }
      }
    }
    return Promise.reject(err);
  }
);

export default api;
